export function formatNumberdigit(number) {
    var numberString = number === null || number === void 0 ? void 0 : number.toFixed(3);
    var integerPart = numberString === null || numberString === void 0 ? void 0 : numberString.split('.')[0];
    var decimalPart = numberString === null || numberString === void 0 ? void 0 : numberString.split('.')[1];
    if (!decimalPart)
        return '';
    var decimalPartAllZero = (decimalPart === null || decimalPart === void 0 ? void 0 : decimalPart.split('').every(function (val) { return val === '0'; })) && integerPart === '0';
    var groups = [];
    for (var i = integerPart === null || integerPart === void 0 ? void 0 : integerPart.length; i > 0; i -= 3) {
        groups.unshift(integerPart === null || integerPart === void 0 ? void 0 : integerPart.slice(Math.max(i - 3, 0), i));
    }
    return decimalPartAllZero
        ? '0'
        : "".concat(groups.join(' ')).concat(decimalPart === '000' ? '' : ".".concat(decimalPart));
}
