import numeral from 'numeral';
import { formatNumberdigit } from './formatNumberdigit';
export function fNumber(number) {
    return numeral(number).format();
}
export function fCurrency(number) {
    var format = number ? numeral(number).format('$0,0.00') : '';
    return result(format, '.00');
}
export function fPercent(number) {
    var format = number ? numeral(Number(number) / 100).format('0.0%') : '';
    return result(format, '.0');
}
export function fShortenNumber(number) {
    var format = number ? numeral(number).format('0.00a') : '0';
    return result(format, '.00');
}
export function fData(number) {
    var format = number ? numeral(number).format('0.0 b') : '';
    return result(format, '.0');
}
function result(format, key) {
    if (key === void 0) { key = '.00'; }
    var isInteger = format.includes(key);
    return isInteger ? format.replace(key, '') : format;
}
export var removeDecimalNumber = function (number) { return formatNumberdigit(Math.trunc(number)); };
